import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Icon, MenuItem, Tooltip } from "@ax/components";
import { IContentFilter } from "@ax/types";

import * as S from "./style";

const MenuGroup = (props: IProps): JSX.Element => {
  const { filter, current, isAllowedToCreate, isAllowedToAccessPrivate, onClick, addNew } = props;

  const [isOpen, setIsOpen] = useState(true);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const selected = filter.items && filter.items.some((filter) => filter.value === current);
    if (selected) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [current, filter]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleClick = (value: string, fromPage = false, firstTemplate: string | null = null) =>
    onClick(value, fromPage, firstTemplate);

  const icon = isOpen ? "UpArrow" : "DownArrow";

  const extendedAction = {
    icon: "add",
    action: addNew,
    onlyOnHover: false,
  };

  return (
    <S.Item>
      <Tooltip content={filter.description}>
        <S.NavLink onClick={toggleOpen} selected={isSelected && !isOpen}>
          <S.Title>{filter.label}</S.Title>
          <S.Arrow>
            <Icon name={icon} />
          </S.Arrow>
        </S.NavLink>
      </Tooltip>
      <S.Dropdown isOpen={isOpen}>
        {filter.items &&
          filter.items.map((filter) => {
            const { label, value, fromPage, firstTemplate, editable, isPrivate = false } = filter;

            const isSelected = value === current;
            const selectedClass = isSelected ? "selected" : "";

            return (
              <MenuItem
                key={filter.value}
                onClick={() => handleClick(value, fromPage, firstTemplate)}
                extendedAction={editable && isAllowedToCreate ? extendedAction : null}
                className={selectedClass}
                disabled={isPrivate && !isAllowedToAccessPrivate}
              >
                <NavLink to="#">
                  <S.Link>
                    <S.Text active={isSelected} disabled={isPrivate && !isAllowedToAccessPrivate}>
                      {label}
                    </S.Text>
                    {isPrivate && (
                      <S.Icon>
                        <Icon name="lock" size="16" />
                      </S.Icon>
                    )}
                  </S.Link>
                </NavLink>
              </MenuItem>
            );
          })}
      </S.Dropdown>
    </S.Item>
  );
};

interface IProps {
  filter: IContentFilter;
  current: string | undefined;
  isAllowedToCreate: boolean;
  isAllowedToAccessPrivate: boolean;
  onClick: (value: string, fromPage: boolean, firstTemplate: string | null) => void;
  addNew: () => void;
}

export default MenuGroup;
