import styled from "styled-components";

const ExtendedWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

const ExtendedAction = styled.div`
  display: none;
`;

const SubItem = styled.li<{ onlyOnHover: boolean; disabled: boolean }>`
  ${(p) => p.theme.textStyle.uiM};
  display: flex;
  align-items: center;
  color: ${(p) => (p.disabled ? p.theme.color.disabled : p.theme.color.textHighEmphasis)};
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  margin-top: ${(p) => p.theme.spacing.xxs};
  position: relative;
  padding: ${(p) => `${p.theme.spacing.xxs} ${p.theme.spacing.xs}`};
  border-radius: ${(p) => p.theme.radii.s};
  min-height: 32px;
  :before {
    content: "";
    border-radius: ${(p) => p.theme.radii.s};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.1s;
  }

  :hover {
    cursor: ${(p) => (p.disabled ? "default" : "pointer")};
    ${ExtendedAction} {
      display: ${(p) => (p.onlyOnHover ? "block" : "none")};
    }
  }

  :hover:before {
    background-color: ${(p) => (p.disabled ? "transparent" : p.theme.color.overlayHoverPrimary)};
    opacity: 1;
  }

  :active:before {
    background-color: ${(p) => (p.disabled ? "transparent" : p.theme.color.overlayPressedPrimary)};
    opacity: 1;
  }

  &.selected {
    ${ExtendedAction} {
      display: ${(p) => (p.onlyOnHover ? "none" : "block")};
    }
    :hover {
      ${ExtendedAction} {
        display: block;
      }
    }
  }

  &.selected:before {
    background-color: ${(p) => p.theme.color.overlayPressedPrimary};
    opacity: 1;
  }
`;

export { ExtendedAction, SubItem, ExtendedWrapper };
