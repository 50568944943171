import * as React from "react";

/**
 * Inject a google structured data into the `<header>`
 *
 * @example
 * <LdJson data={schemaData} />
 */
function LdJson(props: LdJsonProps) {
	const { data } = props;

	return (
		<script
			dangerouslySetInnerHTML={{
				__html: `(function(){
  const ldJsonScript = document.createElement('script');
  ldJsonScript.setAttribute('type', 'application/ld+json');
  ldJsonScript.textContent = \`${JSON.stringify(data)}\`;
  document.head.appendChild(ldJsonScript);
})();`,
			}}
		/>
	);
}

interface LdJsonProps {
	data: Record<string, unknown>;
}

export { LdJson };
