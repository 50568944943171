import React from "react";

import { SessionContext } from "../contexts";

/**
 * `useSession()`
 *
 * Hook to read/write any data in the context throughout the session in CX.
 * @see [Documentation](https://www.notion.so/griddoio/useSession-e446c9f55af34d09b8c27c1fcff529c3)
 *
 * @example
 * const [state, setState] = useSession()
 */
const useSession = () => {
	return React.useContext(SessionContext);
};

export { useSession };
