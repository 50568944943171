const getPermission = (type: "duplicate" | "create" | "delete" | "publish", isGlobal: boolean, isPrivate: boolean) => {
  const permissions: Record<
    "duplicate" | "create" | "delete" | "publish",
    Record<"global" | "site", Record<"default" | "private", string>>
  > = {
    duplicate: {
      global: {
        default: "global.globalData.duplicateGlobalData",
        private: "global.content.duplicatePrivateContentTypes",
      },
      site: {
        default: "content.duplicatePages",
        private: "content.duplicatePrivateContentTypes",
      },
    },
    create: {
      global: {
        default: "global.globalData.createAllGlobalData",
        private: "global.content.createPrivateContentTypes",
      },
      site: {
        default: "content.createPages",
        private: "content.createPrivateContentTypes",
      },
    },
    delete: {
      global: {
        default: "global.globalData.deleteAllGlobalData",
        private: "global.content.deletePrivateContentTypes",
      },
      site: {
        default: "content.deletePages",
        private: "content.deletePrivateContentTypes",
      },
    },
    publish: {
      global: {
        default: "global.globalData.publishUnpublishAllGlobalData",
        private: "global.content.publishUnpublishPrivateContentTypes",
      },
      site: {
        default: "content.publishUnpublishPages",
        private: "content.publishUnpublishPrivateContentTypes",
      },
    },
  };

  const privateStr = isPrivate ? "private" : "default";
  const global = isGlobal ? "global" : "site";

  return permissions[type][global][privateStr];
};

export { getPermission };
