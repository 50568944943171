import React from "react";
import { IconAction } from "@ax/components";

import * as S from "./style";

const MenuItem = (props: IMenuItemProps): JSX.Element => {
  const { children, onClick, extendedAction, className, disabled = false } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (onClick !== undefined && !disabled) {
      e.preventDefault();
      onClick(e);
    }
  };

  const handleExtendedAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    extendedAction && extendedAction.action();
  };

  return (
    <S.SubItem
      onClick={handleOnClick}
      className={className}
      onlyOnHover={(extendedAction && extendedAction.onlyOnHover) ?? true}
      data-testid="menu-subitem"
      disabled={disabled}
    >
      <div>{children}</div>
      {extendedAction && (
        <S.ExtendedWrapper>
          <S.ExtendedAction data-testid="menu-extended-action">
            <IconAction icon={extendedAction.icon} size="s" onClick={handleExtendedAction} />
          </S.ExtendedAction>
        </S.ExtendedWrapper>
      )}
    </S.SubItem>
  );
};

export interface IMenuItemProps {
  children: JSX.Element | string;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  extendedAction?: { icon: string; action: () => void; onlyOnHover?: boolean } | null;
  className?: string;
  disabled?: boolean;
}

export default MenuItem;
